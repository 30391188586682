<template>
  <v-layout row wrap class="d-flex justify-center mt-10">
    <v-flex xs12 md8 lg6 class="card-bg pa-10 border-radius-10">
      <h1 class="primary--text text-center">Sign in as</h1>
      <v-btn class="pl-10 pr-10 primary--text mt-10" outlined x-large block @click="selectMode('Agent')">AGENT</v-btn>
      <v-btn class="pl-10 pr-10 primary--text mt-5" outlined x-large block @click="selectMode('Admin')">ADMIN</v-btn>
    </v-flex>
  </v-layout>
</template>

<style scoped>
.card-bg {
  background: #212121;
}
.border-radius-5 {
  border-radius: 5px;
}
.border-radius-10 {
  border-radius: 10px;
}

</style>

<script>
export default {
    methods: {
    selectMode(mode) {
      this.$emit('mode-selected', mode);
    }
  }
}
</script>