<template>
  <div>
    <agent-reports></agent-reports>
    <v-tabs v-model="tab">
      <v-tab>Schedule</v-tab>
      <v-tab>Booking</v-tab>
      <v-tab>Transactions</v-tab>
    </v-tabs>
    <v-tabs-items v-model="tab">
      <v-tab-item >
        <agent-schedules></agent-schedules>
      </v-tab-item>
      <v-tab-item >
       <agent-bookings></agent-bookings>
      </v-tab-item>
      <v-tab-item >
        <agent-payment></agent-payment>
      </v-tab-item>
    </v-tabs-items>
  </div>
</template>

<script>
import { BASE_URL } from "@/config";
import Axios from "axios";
import { mapGetters } from "vuex";
import AgentBookings from "../../components/AgentBookings.vue";
import AgentSchedules from '../../components/AgentSchedules.vue';
import AgentPayment from "../../components/AgentPayment.vue";
import AgentReports from "../../components/AgentReports.vue";

export default {
  data() {
    return {
      account: {},
      tab : ""
    };
  },
  components : {
    AgentBookings,AgentPayment,AgentSchedules,AgentReports
  },
  computed: {
    ...mapGetters(["USER"]),
  },
  mounted() {
    // this.fetchAccount();
  },
  methods: {
    async fetchAccount() {
      let url = BASE_URL + "/agent/" + this.USER.agent.id;
      let { data } = await Axios.get(url);
      this.account = data;
    },
  },
};
</script>