import Vue from 'vue'
import Vuex from 'vuex'
import createPersistedState from "vuex-persistedstate";


Vue.use(Vuex)

export default new Vuex.Store({
  plugins: [createPersistedState()],
  state: {
    user : {},
    progress : false,
    
  },
  getters : {
    USER : state =>{
      return state.user
    },
    PROGRESS : state => {
      return state.progress
    },
   
  },
  mutations: {
    SET_USER : (state,payload) => {
      state.user = payload
    },
    SET_PROGRESS : (state,payload) => {
      state.progress = payload
    },
    
  },
  actions: {
    SET_USER : (context,payload) => {
      context.commit("SET_USER",payload)
    },
    SET_PROGRESS : (context,payload) => {
      context.commit("SET_PROGRESS",payload)
    },
    
  },
  modules: {
  }
})
