<template>
  <div style="background-color: #212121;" class="pa-5 mb-10">
    <v-layout row wrap class="primary--text">
      <v-flex xs12 md4>
        <h2>Bookings - {{ bookings }}</h2></v-flex
      >
      <v-flex xs12 md4>
        <h2>Total Revenue - {{ total_revenue }} AED</h2>
      </v-flex>
      <v-flex xs12 md4>
        <h2>Payment Due - {{ amount_due }} AED</h2>
      </v-flex>
      <v-flex xs12 md4>
        <h2>Payment Due Date - {{ next_payment_date }}</h2></v-flex
      >
      <v-flex xs12 md4>
        <h2>Last Payment Date - {{ last_payment_date }}</h2>
      </v-flex>
      <v-flex xs12 md4>
        <h2>Last Paid Amount - {{ last_payment_amount }} AED</h2>
      </v-flex>
    </v-layout>
  </div>
</template>

<script>
export default {
  data() {
    return {
      bookings: 0,
      amount_due: 0,
      total_revenue: 0,
      last_payment_date: "",
      last_payment_amount: 0,
      next_payment_date: "",
    };
  },
  mounted() {
    this.fetchBookings();
  },
  methods: {
    async fetchBookings() {
      const headers = {
        Authorization:
          "JWT eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJfaWQiOiI2NTQ0ODMyYjliYWFmMDVkMzNhNDEwYTMiLCJjaGFubmVsIjoiQm9va2luZy5jb20iLCJjcmVhdGVkQXQiOiIyMDIzLTExLTAzVDA1OjIwOjQzLjg2NVoiLCJ1cGRhdGVkQXQiOiIyMDIzLTExLTAzVDA1OjIwOjQzLjg2NVoiLCJfX3YiOjAsImlhdCI6MTY5OTE3MDEzOX0.iS7s7eHzP_yTGD0WIFYEoFSo9HvAuVfhawHkRpv2bls",
      };
      let url = BASE_URL + "/booking/agent/list";
      let { data } = await Axios.get(url, { headers });
      this.bookings = data;
    },
  },
};
</script>
