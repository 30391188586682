import Vue from 'vue';
import Vuetify from 'vuetify/lib/framework';

Vue.use(Vuetify);

export default new Vuetify({
    theme : {
        dark : true,
        default : 'Blinker, sans-serif',
        themes : {
            dark : {
                primary : "#ba9d73",
                accent : "#afbda1",
                
            },
            fonts : {
                body : 'Blinker, sans-serif',
                heading : 'Blinker, sans-serif',
            },
            
        }
    }
});
