<template>
    <v-layout row wrap class="d-flex justify-center">
      <v-flex xs10 class="mb-5">
        <h3 class="primary--text">Welcome back, {{ USER.admin.name }}</h3>
        <h1 class="f-alfa primary--text ">Super Admin</h1>
      </v-flex>
      <v-flex xs2 class="d-flex align-center justify-end">
        <v-btn class="accent--text" @click="logout">Logout</v-btn>
      </v-flex>
      <v-flex xs12 md6 lg4 class="pa-5" @click="flightStatus">
        <div class="menu-item">
          <h3 class="primary--text fw-bold">Flight Status</h3>
        </div>
      </v-flex>
      <v-flex xs12 md6 lg4 class="pa-5" @click="integrations">
        <div class="menu-item">
          <h3 class="primary--text">Integrations</h3>
        </div>
      </v-flex>
      <!-- <v-flex xs12 md6 lg4 class="pa-5" @click="operations">
        <div class="menu-item-disabled">
          <h3 class="primary--text">Operations</h3>
        </div>
      </v-flex> -->
      <v-flex xs12 md6 lg4 class="pa-5" @click="agents">
        <div class="menu-item">
          <h3 class="primary--text">Agents</h3>
        </div>
      </v-flex>
      <v-flex xs12 md6 lg4 class="pa-5" @click="schedule">
        <div class="menu-item">
          <h3 class="primary--text"> Schedule</h3>
        </div>
      </v-flex>
      <v-flex xs12 md6 lg4 class="pa-5" @click="customerService">
        <div class="menu-item">
          <h3 class="primary--text">Customer Service</h3>
        </div>
      </v-flex>
      <v-flex xs12 md6 lg4 class="pa-5" @click="users">
        <div class="menu-item">
          <h3 class="primary--text">User Management</h3>
        </div>
      </v-flex>
      <v-flex xs12 md6 lg4 class="pa-5" @click="promotions">
        <div class="menu-item">
          <h3 class="primary--text">Promotions</h3>
        </div>
      </v-flex>
     <v-flex xs12 md6 lg4 class="pa-5" @click="booking">
        <div class="menu-item">
          <h3 class="primary--text">Bookings</h3>
        </div>
      </v-flex>
      <v-flex xs12 md6 lg4 class="pa-5" @click="masterListings">
        <div class="menu-item">
          <h3 class="primary--text">Master Listings</h3>
        </div>
      </v-flex>
      <v-flex xs12 md6 lg4 class="pa-5">
        <div class="menu-item-disabled">
          <h3 class="primary--text">Maintenance</h3>
        </div>
      </v-flex>
      <v-flex xs12 md6 lg4 class="pa-5">
        <div class="menu-item-disabled">
          <h3 class="primary--text">Real Estate</h3>
        </div>
      </v-flex>
      <v-flex xs12 md6 lg4 class="pa-5" @click="reports">
        <div class="menu-item">
          <h3 class="primary--text">Reports</h3>
        </div>
      </v-flex>
    </v-layout>
</template>

<script>
// @ is an alias to /src
import { mapGetters } from 'vuex'
export default {
  name: 'Home',
  components: {
    
  },
  computed: {
    ...mapGetters(['USER'])
  },
  methods : {
    logout(){
      
      this.$store.dispatch("SET_USER", null);
      this.$toastr.s(
            "LOGGED OUT",
            "Logged out of Ingenious Engine"
          );
          this.$router.push("/");
    },
    integrations(){this.$router.push('/integrations')},
    flightStatus(){this.$router.push('/flight-status')},
    masterListings(){this.$router.push('/master-listings')},
    operations(){},
    booking(){this.$router.push('/bookings')},
    users(){this.$router.push('/accounts')},
    schedule(){this.$router.push('/schedules')},
    customerService(){this.$router.push('/support/cases')},
    reports(){this.$router.push('/reports')},
    agents(){this.$router.push('/agents')},
    promotions(){this.$router.push('/promotions')}
  }
}
</script>

<style scoped>
.menu-item {
  cursor: pointer;
  border: 2px solid #ba9d73;
  border-radius: 10px;
  padding: 20px 10px;
  box-shadow: 5px 5px 10px rgba(186, 157, 115, 1);
}
.menu-item-disabled {
  cursor: not-allowed;
  border: 2px solid #ba9d73;
  border-radius: 10px;
  padding: 20px 10px;
  box-shadow: 5px 5px 10px rgba(186, 157, 115, 1);
}
.fw-bold{
  font-weight: 700 !important;
}
h3{
  margin: 0px !important;
  padding: 0px !important;
}
</style>
