<template>
  <div class="">
    <v-layout row wrap class="">
      <v-flex xs12 md12 lg12 class="pa-5">
        <h1 class="f-alfa primary--text pl-5 pr-5">
           Schedules
        </h1>
      </v-flex>

      <v-flex xs12 class="pa-5 d-flex">
        <!-- <div>
          <v-date-picker v-model="dates" range></v-date-picker>
        </div> -->
        
        <div class="pl-5 pr-5" style="width: 100%;" >
          <div class="d-flex gap-2">
            <div class="pr-2">
              <v-menu
                v-model="menu2"
                :close-on-content-click="false"
                :nudge-right="40"
                transition="scale-transition"
                offset-y
                min-width="auto"
              >
                <template v-slot:activator="{ on, attrs }">
                  <p class="pa-0 ma-0">Date Range</p>
                  <v-text-field
                    v-model="dates"
                    single-line
                    outlined
                    readonly
                    v-bind="attrs"
                    v-on="on"
                  ></v-text-field>
                </template>
                <v-date-picker
          v-model="dates"
          range
          
          no-title
          scrollable
        >
          <v-spacer></v-spacer>
         
          <v-btn
            text
            color="primary"
            @click="menu2 = false"
          >
            OK
          </v-btn>
        </v-date-picker>
                  <!-- <v-date-picker
                    v-model="dates"
                    range
                    @input="menu2 = false"
                  ></v-date-picker> -->
              </v-menu>
              <!-- <v-date-picker v-model="dates" range></v-date-picker>
          <div class="grey darken-3 mt-3 pa-2" style="border-radius: 3px">
            {{ dates }}
          </div> -->
            </div>
            <div class="pr-2">
              <p class="pa-0 ma-0">Duration</p>
              <v-select
                :items="durations"
                label="Duration"
                single-line
                outlined
                v-model="duration"
              ></v-select>
            </div>
            <div class="pr-2">
              <p class="pa-0 ma-0">Day / Night</p>
              <v-select
                :items="types"
                label="Type"
                single-line
                outlined
                v-model="type"
              ></v-select>
            </div>
            <div class="pr-2">
              <p class="pa-0 ma-0">Start Time</p>
              <v-text-field
                single-line
                outlined
                v-model="startTime"
                label="Ex. 1020"
              ></v-text-field>
            </div>
            <div>
              <p class="pa-0 ma-0">End Time</p>
              <v-text-field
                single-line
                outlined
                v-model="endTime"
                label="Ex. 1250"
              ></v-text-field>
            </div>
          </div>
          <div class="pr-2 pt-5">
            <v-btn class="primary black--text" x-large @click="fetchSchedules"
              >Filter</v-btn
            >
            <v-btn x-large @click="clearFilters" class="ml-5">Clear</v-btn>
          </div>
          <div>
            <v-divider class="mt-5 mb-5"></v-divider>
          </div>
          <v-simple-table>
            <template v-slot:default>
              <thead>
                <tr>
                  <!-- <th class="text-left uppercase primary--text">Flight</th> -->
                  <th class="text-left uppercase primary--text">date</th>
                  <th class="text-left uppercase primary--text">Take off</th>
                  <th class="text-left uppercase primary--text">Duration</th>
                  <th class="text-left uppercase primary--text">Landing</th>
                  <th class="text-left uppercase primary--text">Seats</th>
                  <th class="text-left uppercase primary--text">Net Amt (AED)</th>
                  <th class="text-left uppercase primary--text">P. Amt (AED)</th>
                  <th></th>
                </tr>
              </thead>
              <tbody>
                <tr v-for="item in schedules" :key="item._id">
                  <!-- <td>{{ item.ac_unique_code }}</td> -->
                  <td class="primary--text"><b>{{ item.date }}</b></td>
                  <td>{{ item.flight_take_off }}</td>
                  <td>{{ item.duration }}</td>
                  <td>{{ item.landing_time }}</td>
                  <td>{{ item.seats_available }} / {{ item.seats }}</td>
                  <td>{{ item.net_amount }}</td>
                  <td>{{item.net_amount * .80 }}</td>
                  <td><v-btn small class="primary" @click="openBooking(item)"><v-icon class="black--text" style="font-size:18px">mdi-arrow-right</v-icon></v-btn></td>
                </tr>
              </tbody>
            </template>
          </v-simple-table>
        </div>
        <div class="pl-5 pr-5"></div>
      </v-flex>
      <v-flex xs12 md12 class="pa-5"> </v-flex>
    </v-layout>
    <v-dialog v-model="dialog" width="800" persistent v-if="selectedSchedule">
      <v-card>
        <v-card-title class="text-h5 primary--text grey darken-3">
          Place Booking
        </v-card-title>
        <v-card-text>
          <v-simple-table>
            <tbody>
              <tr>
                <td>Date</td>
                <td>{{selectedSchedule.date}}</td>
                <td>Flight Take off</td>
                <td>{{selectedSchedule.flight_take_off}}</td>
              </tr>
              <tr>
                <td>Duration</td>
                <td>{{selectedSchedule.duration}}</td>
                <td>Net Amount</td>
                <td>{{selectedSchedule.net_amount}}</td>
              </tr>
              <tr>
                <td>Payable Amount</td>
                <td>{{selectedSchedule.net_amount * .80 }}</td>
                <td>Seats</td>
                <td><v-select :items="seats" v-model="seat"></v-select></td>
              </tr>
            </tbody>
          </v-simple-table>
          <v-divider class="mb-3"></v-divider>
          <v-layout row wrap>
            <v-flex xs12 md6 class="pa-2">
              <v-text-field label="Full Name" single-line outlined v-model="name"></v-text-field>
            </v-flex>
            <v-flex xs12 md6 class="pa-2">
              <v-text-field label="Email" single-line outlined v-model="email"></v-text-field>
            </v-flex>
            <v-flex xs12 md6 class="pa-2">
              <v-text-field label="Phone" single-line outlined v-model="phone"></v-text-field>
            </v-flex>
            <v-flex xs12 md6 class="pa-2">
              <v-text-field label="Whatsapp" single-line outlined v-model="whatsapp"></v-text-field>
            </v-flex>
            <v-flex xs12 md6 class="pa-2">
              <v-btn class="primary black--text" large @click="placeBooking">Place Booking</v-btn>
            </v-flex>
            
          </v-layout>
        </v-card-text>

       

        <v-card-actions>
          <v-spacer></v-spacer>
          
          <v-btn color="primary" text @click="dialog = false"> CLOSE </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import { BASE_URL } from "@/config";
import Axios from "axios";
import { mapGetters } from "vuex";
export default {
  data() {
    return {
      name : "",
      email : "",
      phone : "",
      whatsapp : "",
      selectedSchedule : null,
      seats : ["1","2","3","4","5"],
      seat : "1",
      dialog: false,
      menu2: false,
      filter: false,
      schedules: [],
      loading: false,
      durations: ["no filter", 12, 15, 18],
      dates: [
        new Date(Date.now() - new Date().getTimezoneOffset() * 60000)
          .toISOString()
          .substr(0, 10),
      ],
      duration: "no filter",
      type: "no filter",
      types: ["no filter", "day", "night"],
      startTime: "",
      endTime: "",
      
    };
  },
  mounted() {
    this.fetchSchedules();
  },
  computed: {
    getFilterButtonClass() {
      if (this.filter) {
        return "primary black--text";
      } else {
      }
    },
    ...mapGetters(["USER"])
  },
  filters: {
    round(value) {
      return Math.round(value * 100) / 100; // Rounds off to two decimal places
    }
  },
  methods: {
    async placeBooking(){
      let payload = {
        mobile_number : this.phone,
        name : this.name,
        email : this.email,
        whatsapp : this.whatsapp,
        date : this.selectedSchedule.date,
        time : this.selectedSchedule.time,
        seats : this.seat,
        status : "confirmed",
        channel : "AGENT",
        amount : this.selectedSchedule.net_amount * .80,
        ac_unique_code : this.selectedSchedule.ac_unique_code,
        duration : this.selectedSchedule.duration,
        agent : this.USER.agent._id
      }
      let url = BASE_URL + "/booking/agent"
      let {data} = await Axios.post(url,payload)
      alert(JSON.stringify(data))
    },
    openBooking(schedule){
      this.selectedSchedule = schedule
      this.dialog = true
    },
    roundValue(value) {
      return Math.round(value * 100) / 100; // Rounds off to two decimal places
    },
    async fetchSchedules() {
      var q_params = "?";
      if (this.dates.length == 1) {
        q_params += "date=" + this.dates[0];
      } else {
        var _d = this.getStartEndDate();
        q_params += "startDate=" + _d.startDate + "&endDate=" + _d.endDate;
      }
      if (this.startTime && this.endTime) {
        q_params += "&startTime=" + this.startTime + "&endTime=" + this.endTime;
      }
      if (this.duration != "no filter") {
        q_params += "&duration=" + this.duration;
      }
      if (this.type != "no filter") {
        q_params += "&type=" + this.type;
      }
      this.$store.dispatch("SET_PROGRESS", true);
      let url = BASE_URL + "/schedule" + q_params;
      let { data } = await Axios.get(url);
      this.schedules = data.schedules;
      this.$toastr.s("Apply filters to tweak your data", "SCHEDULES LOADED");
      this.$store.dispatch("SET_PROGRESS", false);
    },
    getStartEndDate() {
      var date1 = new Date(this.dates[0]);
      var date2 = new Date(this.dates[1]);
      var startDate = date1 < date2 ? date1 : date2;
      var endDate = date1 < date2 ? date2 : date1;
      return {
        startDate: startDate.toISOString().split("T")[0], // Format as 'YYYY-MM-DD'
        endDate: endDate.toISOString().split("T")[0], // Format as 'YYYY-MM-DD'
      };
    },
    clearFilters() {
      (this.dates = [
        new Date(Date.now() - new Date().getTimezoneOffset() * 60000)
          .toISOString()
          .substr(0, 10),
      ]),
        (this.duration = "no filter");
      this.type = "no filter";
      this.startTime = "";
      this.endTime = "";
      this.$toastr.s("Filters reset", "CLEARED");
      this.fetchSchedules();
    },
  },
};
</script>